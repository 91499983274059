<template>
  <VCategoriesList
    :categories="categories"
    is-more
    is-max-width
    is-close-emit
    class-link="bg--gray"
    @close="deleteCategory"
  />

  <VTradeProcedure
    is-chosen-icon
    :is-checkbox="false"
    :url-action-query="getUrlActionQuery"
    url-set-rows="trade-offer/lots"
  />
</template>
<script>
import VTradeProcedure from '@/components/views/TradeProcedure/VTradeProcedure'
import VCategoriesList from '@/components/list/CategoriesList/VCategoriesList'
import { computed, onBeforeMount, reactive } from 'vue'
import { useStore } from 'vuex'
import { EXPAND_LOT } from '@/utils/consts'
import { refreshArr } from '@/utils/reactive'
import { getIdsString } from '@/utils/array'
import { spliceBy } from '@/utils/utils'

export default {
  setup () {
    const store = useStore()
    const categories = reactive([])
    const searchQuery = `${EXPAND_LOT}&search[Active]=active`

    function setCategories () {
      const userCategory = store.getters['auth/getUser']?.category
      if (!userCategory?.length) return

      refreshArr(categories, userCategory)
    }

    onBeforeMount(setCategories)

    const deleteCategory = cat => spliceBy(cat.id, categories)

    const categoriesIds = computed(() => getIdsString(categories))

    const getUrlActionQuery = computed(() => {
      if (!categoriesIds.value) return searchQuery

      const searchCategory = '&search[Category]='
      return searchQuery + searchCategory + categoriesIds.value
    })

    return {
      getUrlActionQuery,
      deleteCategory,
      categories
    }
  },
  components: {
    VCategoriesList,
    VTradeProcedure
  }
}
</script>
